<template>
  <div class="simple_preload">
      <div class="box_preloader"></div>
  </div>
</template>

<script lang="ts" src="@/presentation/components/preloader/simple-preloader/SimplePreloader.ts">
</script>

<style lang="scss" src="@/presentation/components/preloader/simple-preloader/SimplePreloader.scss" scoped>
</style>